import * as React from "react";
import { SEO } from "@components/page-layout";
import { Box } from "@components/box";
import { ResetPassword } from "@containers/reset-password";

const ResetPasswordPage = () => (
  <>
    <SEO title="Reset password" bodyClass="signup" />
    <Box className="flex justify-center items-center px-[22px] sm:px-[11px] xs:p-0">
      <ResetPassword />
    </Box>
  </>
);

export default ResetPasswordPage;