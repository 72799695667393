import * as React from "react";
import { navigate } from "gatsby";

import { Box } from "@components/box";
import { Subheading } from "@components/typography";
import { Button, PasswordInput, ValidationMessage } from "@brainfinance/icash-component-library";
import { SignUpContainer, SignUpLeft, SignUpRight } from "@components/sign-up";
import { postResetPassword, setLocalStorageUserSession } from "@helpers/auth";
// Images
import instantLoans from "@images/products/ic-instant-loans-xl.svg";
import interac from "@images/interac.svg";

const getURLParameter = (sParam: string) => {
  const sPageURL = window.location.search.substring(1);
  const sURLVariables = sPageURL.split("?");
  for (var i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split("=");
    if (sParameterName[0] == sParam) {
      return decodeURIComponent(sParameterName[1]);
    }
  }
};

export const ResetPassword = () => {
  const [password, setPassword] = React.useState<string | undefined>();
  const [confirmPassword, setConfirmPassword] = React.useState<string>();
  const [confirmCode, setConfirmCode] = React.useState<string>();
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const handlePasswordlChange = (password?: string | number) => {
    if (password) setPassword(password.toString());
    else setPassword(undefined);
  };

  const handleSubmitPassword = () => {
    if (password && confirmCode)
      postResetPassword(password, confirmCode)
        .then((res) => res.json())
        .then((data) => {
          if (data.error) throw new Error(data.error.message);
          if (!data.result?.session?.token) throw new Error("An error occurred. Please contact the support team.");
          setLocalStorageUserSession(data.result.session.token);
          navigate("/dashboard");
        })
        .catch((e) => setErrorMessage(e));
  };

  React.useEffect(() => {
    // url example: /reset-password/?code=MjAxOC0xMS0yMyAxMDoyMToxM3xqYkBicmFpbmZpbmFuY2UuY29tfDEzNjE3MnwyMDIyLTA1LTI1IDE3OjE2OjQ2
    setConfirmCode(getURLParameter("code"));
  }, []);

  return (
    <SignUpContainer>
      <SignUpLeft className="max-w-[475px] md:max-w-none md:w-1/2">
        <Box display="flex" className="flex-col">
          <Box className="mb-[3.25rem] md:mb-[33px]">
            <Subheading>Reset password</Subheading>
          </Box>
          {errorMessage ? (
            <ValidationMessage className="mb-[33px]" status="error">
              {errorMessage}
            </ValidationMessage>
          ) : null}
          <Box>
            <p className="global--caption">
              Your password must contain at least 8 characters, 1 number, 1 uppercase and 1 lowercase letter
            </p>
            <Box className="space-y-1 mt-[22px] mb-[33px]">
              <PasswordInput id="password" label="Enter your new password" onChange={handlePasswordlChange} />
              <PasswordInput id="confirmPassword" label="Confirm your new password" onChange={setConfirmPassword} />
            </Box>
            <Box display="flex" className="justify-end">
              <Button
                size="large"
                appearance="primary"
                disabled={!password || password !== confirmPassword}
                onClick={handleSubmitPassword}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </SignUpLeft>
      <SignUpRight className="w-[845px] lg:w-[590px] md:w-1/2">
        <>
          <img
            src={instantLoans}
            className="absolute h-[527px] right-0 t-[46px] z-0 lg:h-[368px] md:h-[260px]"
            alt="Product image"
          />
          <Box className="max-w-[330px] mt-[34%] z-[1] lg:max-w-[240px] lg:mt-[50%] md:mt-[70%]">
            <Subheading className="mb-[44px] md:text-[26px] md:leading-[33px]">
              Instant loans, 24 hours a day
            </Subheading>
            <img src={interac} alt="Interac logo" className="w-[60px]" />
            <p className="global--small !text-[12px] text-interface-300 my-[22px]">
              With iCash instant loans, you can borrow up to $1500, 24/7. Receive your funds via e-Transfer almost
              instantaneously after signing your contract.
            </p>
          </Box>
        </>
      </SignUpRight>
    </SignUpContainer>
  );
};
